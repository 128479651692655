import React, { useState } from "react"
import styled from "styled-components"

import Navigation from "./navigation"
import MenuIcon from "../../images/icons/menu-burger-icon"
import CloseIcon from "../../images/icons/menu-close-icon"

const MobileNavigation = ({
  aboutRef,
  curriculumRef,
  feesRef,
  staffRef,
  enrolRef,
  contactRef,
  navLinks,
}) => {
  const [menuState, setMenuState] = useState(false)

  const handleMenuClick = () => {
    setMenuState(state => !state)
  }

  return (
    <MobileNavContainerStyling>
      <MenuIconStyling onClick={handleMenuClick}>
        {menuState ? <CloseIcon /> : <MenuIcon />}
      </MenuIconStyling>
      {menuState && (
        <LinkContainerStyling onClick={handleMenuClick}>
          <Navigation
            aboutRef={aboutRef}
            curriculumRef={curriculumRef}
            feesRef={feesRef}
            staffRef={staffRef}
            enrolRef={enrolRef}
            contactRef={contactRef}
            navLinks={navLinks}
          />
        </LinkContainerStyling>
      )}
    </MobileNavContainerStyling>
  )
}

export default MobileNavigation

// ===============
//     STYLES
// ===============

const MobileNavContainerStyling = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
`
const MenuIconStyling = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg:hover > g {
    stroke: ${({ theme }) => theme.colors.red};
  }
`
const LinkContainerStyling = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: space-between;
  position: absolute;
  top: 45px;
  right: -50px;
  background: ${({ theme }) => theme.colors.lightgrey};
  padding: 20px 50px;

  @media only screen and (max-width: ${({ theme }) => theme.breakSmall}) {
    top: 40px;
  }
`
