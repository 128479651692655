/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import ThemeConfig from "./theme-config"
import Footer from "./footer/footer"

const Layout = ({
  children,
  aboutRef,
  curriculumRef,
  feesRef,
  staffRef,
  enrolRef,
  contactRef,
  headerContent,
  navLinks
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ThemeConfig>
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          aboutRef={aboutRef}
          curriculumRef={curriculumRef}
          feesRef={feesRef}
          staffRef={staffRef}
          enrolRef={enrolRef}
          contactRef={contactRef}
          headerContent={headerContent}
          navLinks={navLinks}
        />
        <div>
          <main>{children}</main>
        </div>
        <Footer />
      </ThemeConfig>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
