import ky from "ky"

export const kyWrapped = (
  url,
  { method = "get", logout = () => {}, ...content }
) => {
  const options = {
    retry: {
      limit: 3,
      statusCodes: [408, 413, 429, 502, 503, 504],
    },
    timeout: 20000,
  }
  switch (method) {
    case "get":
      return ky
        .get(url, { ...content, ...options })
        .json()
        .catch((error) => handleApiException(error, logout))
    case "post":
      return ky
        .post(url, { ...content, ...options })
        .json()
        .catch((error) => handleApiException(error, logout))
    case "put":
      return ky
        .put(url, { ...content, ...options })
        .json()
        .catch((error) => handleApiException(error, logout))
    case "delete":
      return ky
        .delete(url, { ...content, ...options })
        .json()
        .catch((error) => handleApiException(error, logout))
  }
  return
}

// This function will ensure that if the response has any decodable json, it will return it
// Otherwise it will return an empty object
const handleApiException = async (error, logout) => {
  if (error.response) {
    const errorContent = await error.response.json()
    console.log("Request error decoded: ", errorContent)
    if (
      errorContent.code.includes("invalid_token") ||
      errorContent.code.includes("no_user") ||
      errorContent.code.includes("bad_iss")
    ) {
      logout()
      throw { message: "You need to be logged in to perform this action" }
    }
    if (errorContent.message) {
      throw errorContent
    }
  } else {
    console.log("Request error cannot be decoded: ", error)
  }
  throw {}
}

export const getProperty = (
  inputObject,
  propertyPath,
  defaultReturn = null
) => {
  try {
    let returnVal = inputObject
    for (let i = 0; i < propertyPath.length; ++i) {
      const newKey = propertyPath[i]
      if (!Object.prototype.hasOwnProperty.call(returnVal, newKey)) {
        throw new TypeError(`Cannot read property '${newKey}'`)
      }
      returnVal = returnVal[newKey]
    }
    return returnVal
  } catch (error) {
    // if (process.env.GATSBY_ACTIVE_ENV == "development") {
    //   console.warn(`getProperty: ${error}`)
    // }
  }
  return defaultReturn
}

export const scrollTop = () =>
  typeof window !== "undefined" && window.scroll({ top: 0, behavior: "smooth" })

export const scrollToY = (y) =>
  typeof window !== "undefined" && window.scroll({ top: y, behavior: "smooth" })

export const isEmailValid = (emailValue) => /\S+@\S+\.\S+/.test(emailValue)

export const urlEncodeJsonObject = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const isBrowser = () => typeof window !== "undefined"

// Imported from TMW
// Parse URL query values -----------------------------------------------------
export const getQueryVariable = (variable, optCallback = () => {}) => {
  if (typeof window !== "undefined") {
    const currentQuery = new URLSearchParams(window.location.search)
    const value = currentQuery.get(variable)
    if (value) {
      if (optCallback) optCallback(value)
      return value
    }
  }
  if (optCallback) optCallback(false)
  return false
}

// Imported from TMW
export const injectScript = ({
  id,
  parentElement,
  contents = "",
  src = "",
  onLoad = () => {},
}) => {
  // Check if script has been loaded to avoid multiples
  const oldScript = document.getElementById(id)
  let elementToInject = document.getElementsByTagName(parentElement)[0]
  if (oldScript) {
    onLoad() // run the callback again if the script is already loaded
  } else {
    var newScript = document.createElement("script")
    newScript.type = "text/javascript"
    newScript.async = true
    if (src != "") {
      newScript.src = src
    } else {
      newScript.text = contents
    }
    newScript.id = id
    newScript.addEventListener("load", onLoad)
    elementToInject.appendChild(newScript)
  }
}

const locale = "en-US"

// format a number (like a float) according to the local formatting rules (e.g. 1234.567 => "1,234.567")
export const formatNumber = (value, decimals = 0) =>
  Intl.NumberFormat(locale, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value)

// format a number to a ZAR currency string, (e.g. 1234.567 => "R 1,234.57")
export const formatPrice = ({ price, cents = true }) => {
  const priceFloat = parseFloat(price)
  if (Number.isNaN(priceFloat)) {
    return ""
  }
  const priceToFormat = cents ? priceFloat.toFixed(2) : price
  const decimals = cents ? 2 : 0
  return `R ${formatNumber(priceToFormat, decimals, locale)}`
}

const decimalSeparator = "."
// converts a string to a float
export const parseCurrency = (value) => {
  const cleanPattern = new RegExp(`[^-+0-9${decimalSeparator}]`, "g")
  const cleanedValue = value?.replace(cleanPattern, "")

  return parseFloat(cleanedValue)
}

//source: https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
export const slugify = (string) => {
  const specialCharacters =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
  const urlSafeCharacters =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
  const characterRegex = new RegExp(specialCharacters.split("").join("|"), "g")
  const returnString = string || ""

  return returnString
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(characterRegex, (character) =>
      urlSafeCharacters.charAt(specialCharacters.indexOf(character))
    ) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

export const titleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

export const escapeRegExp = (string) =>
  string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string

export const replaceAll = (str, find, replace) =>
  str.replace(new RegExp(escapeRegExp(find), "g"), replace)

// Helpter function to clean up a date string
export const stripExtraDateInfo = (date) => {
  let newDate = date.split(" ")
  if (newDate.length > 0) {
    return newDate[0]
  }
  return date
}

// Helpter function to convert snake_case to camelCase
export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "")
  })
}
