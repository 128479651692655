import React from "react"
import styled from "styled-components"

import FacebookIcon from "../../images/icons/facebook-icon"

const Footer = () => {

  const socialMediaArray = [
    { SocialIcon: FacebookIcon, socialLink: "https://www.facebook.com/pages/category/Preschool/Nicolini-Pre-School-1585891091641590/" }
  ]

  return (
    <StyledFooter>
      <SocialMediaWrapperStyling>
        {socialMediaArray.map(({ SocialIcon, socialLink }, socialIndex) => {
          return !(SocialIcon || socialLink) ? null : (
            <SocialMediaStyling
              key={`social-media-${socialIndex}`}
              href={socialLink}
              target="_blank"
            >
              <div>
                <SocialIcon />
                <p>Find us on Facebook!</p>
              </div>
            </SocialMediaStyling>
          )
        })}
      </SocialMediaWrapperStyling>
      <p>© 2022 Nicolini Nursery School. All rights reserved.</p>
    </StyledFooter>
  )
}

export default Footer

// ===============
//     STYLES
// ===============
const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  p {
    color: white;
    text-align: center;
  }
  padding: 5vh;
`

const SocialMediaWrapperStyling = styled.div`
  display: flex;
  place-content: center;
`
const SocialMediaStyling = styled.a`
  & + & {
    margin-left: 30px;
  }

  & div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & p {
    font-weight: bold;
    margin-left: 20px;
  }
`

// ===============
//      QUERY
// ===============
