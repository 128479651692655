import React from "react";

const MenuIcon = ({ stroke="#171E63" }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="21"
      viewBox="0 0 30 21"
    >
      <g
        fill="none"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        data-name="Icon feather-menu"
        transform="translate(-3 -7.5)"
      >
        <path d="M4.5 18h27" data-name="Path 294"></path>
        <path d="M4.5 9h27" data-name="Path 295"></path>
        <path d="M4.5 27h27" data-name="Path 296"></path>
      </g>
    </svg>
  )

export default MenuIcon;