import React from "react"
import styled from "styled-components"

import { scrollToY, slugify } from "../../lib/utils"

const Navigation = ({
  aboutRef,
  curriculumRef,
  feesRef,
  staffRef,
  enrolRef,
  contactRef,
  navLinks,
  setScrollPosition = event => {},
}) => {
  const refArray = [
    aboutRef,
    curriculumRef,
    staffRef,
    feesRef,
    enrolRef,
    contactRef,
  ]

  const handleClick = (event, navLink) => {
    event?.preventDefault()
    for (let index = 0; index < refArray.length; index++) {
      if (slugify(navLink) === refArray[index]?.current?.id) {
        scrollToY(refArray[index]?.current?.offsetTop - 120)
        setScrollPosition(refArray[index]?.current?.offsetTop - 120)
      }
    }
  }

  return (
    <NavigationStyling className="nav-links">
      {navLinks?.map((navLink, linksIndex) => {
        return (
          <NavItemStyling
            key={`nav-link-${linksIndex}`}
            onClick={e => handleClick(e, navLink)}
          >
            {navLink}
          </NavItemStyling>
        )
      })}
    </NavigationStyling>
  )
}

export default Navigation

// ===============
//     STYLES
// ===============

const NavigationStyling = styled.div`
  display: flex;
`

const NavItemStyling = styled.div`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.red};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    margin: 5px 0;
  }
`
