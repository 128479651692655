import React from "react"

const CloseIcon = ({ stroke="#171E63" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.501"
    height="23.501"
    viewBox="0 0 23.501 23.501"
  >
    <g
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
      data-name="Icon feather-x"
      transform="translate(-6.249 -6.249)"
    >
      <path d="M27.63 8.371L8.371 27.63" data-name="Path 298"></path>
      <path d="M8.371 8.371L27.63 27.63" data-name="Path 299"></path>
    </g>
  </svg>
)

export default CloseIcon
