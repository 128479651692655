import React from "react"

const FacebookIcon = ({ fill = "#fff" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.391"
    height="23.136"
    viewBox="0 0 12.391 23.136"
  >
    <path
      fill={fill}
      d="M13.189,13.014l.643-4.187H9.814V6.11a2.094,2.094,0,0,1,2.361-2.262H14V.283A22.273,22.273,0,0,0,10.759,0C7.45,0,5.287,2.005,5.287,5.636V8.827H1.609v4.187H5.287V23.136H9.814V13.014Z"
      transform="translate(-1.609)"
    />
  </svg>
)

export default FacebookIcon
