import React, { useRef } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Navigation from "./navigation"

import MobileNavigation from "./mobile-navigation"

const Header = ({
  siteTitle,
  aboutRef,
  curriculumRef,
  feesRef,
  staffRef,
  enrolRef,
  contactRef,
  headerContent,
  navLinks,
}) => {
  const headerRef = useRef(null)

  const logo = headerContent?.siteLogo.localFile.publicURL

  return (
    <HeaderContainerStyling ref={headerRef}>
      <div className="header-inner">
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
            className="company-logo"
            onClick={event => {
              event?.preventDefault()
              window.scrollTo({ top: 0, behavior: "smooth" })
            }}
          >
            <img src={logo} alt={siteTitle} />
          </Link>
        </h1>

        <LinkContainerStyling>
          <Navigation
            aboutRef={aboutRef}
            curriculumRef={curriculumRef}
            feesRef={feesRef}
            staffRef={staffRef}
            enrolRef={enrolRef}
            contactRef={contactRef}
            navLinks={navLinks}
          />
        </LinkContainerStyling>

        <MobileMenuContainerStyling>
          <MobileNavigation
            aboutRef={aboutRef}
            curriculumRef={curriculumRef}
            feesRef={feesRef}
            staffRef={staffRef}
            enrolRef={enrolRef}
            contactRef={contactRef}
            navLinks={navLinks}
          />
        </MobileMenuContainerStyling>
      </div>
    </HeaderContainerStyling>
  )
}

export default Header

// ===============
//     STYLES
// ===============

const HeaderContainerStyling = styled.header`
  background: white;
  width: 100%;
  padding: 20px 50px;
  color: ${({ theme }) => theme.colors.navy};
  position: fixed;
  z-index: 999;
  min-height: 90px;

  .header-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: ${({ theme }) => theme.breakLarge};
    margin: 0 auto;
  }

  .company-logo {
    display: flex;
  }

  img {
    height: auto;
    width: auto;
    object-fit: contain;
    margin: 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakLarge}) {
    .header-inner {
      width: 100%;
    }
  }
`

const LinkContainerStyling = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;

  .nav-links {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    display: none;
  }
`

const MobileMenuContainerStyling = styled.div`
  display: none;

  .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: end;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakMedium}) {
    display: flex;

    .nav-links {
      align-items: center;
    }
  }
`
