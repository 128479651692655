import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import Helmet from "react-helmet"

import 'fontsource-poppins/latin.css'

const colors = {
  white: "#ffffff",
  black: "#1f1f1f",
  navy: "#171e63",
  blue: "#00c0d9",
  lightblue: "#55def0",
  red: "#fc4963",
  yellow: "#ffcb55",
  darkgrey: "#404040",
  grey: "#d8d8d8",
  lightgrey: "#F5F5F5",
}

export const windowWidths = {
  desktopHD: 1920,
  desktopSmall: 1240,
  desktopStandard: 1366,
  tabletLarge: 960,
  tabletSmall: 767,
  mobileLarge: 480,
  mobileStandard: 375,
}

const theme = {
  boxShadow: "0px 1px 2px #00000029",
  maxWidth: `${windowWidths.desktopSmall}px`,
  breakLarge: `${windowWidths.desktopSmall}px`,
  breakMedium: `${windowWidths.tabletLarge}px`,
  breakSmall: `${windowWidths.tabletSmall}px`,
  breakTiny: `${windowWidths.mobileLarge}px`,
  breakXTiny: `${windowWidths.mobileStandard}px`,
  headingPrimaryFont: "Poppins, Myriadproboldcond, Segoeui",
  headingSecondaryFont: "Poppins, Myriadproboldcond, Segoeui",
  bannerFont: "Myriadproboldcond, Poppins, Segoeui",
  timeBannerFont: "Segoeui, Poppins, Myriadproboldcond",
  headingColor: colors.navy,
  bodyFont: "Poppins, Myriadproboldcond, Segoeui, sans-serif",
  bodyColor: colors.navy,
  colors,
}

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 1em;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    /* font-family: ${theme.bodyFont}; */
  }


  body {
    padding: 0;
    margin: 0;
    color: ${theme.bodyColor};
    font-family: ${theme.bodyFont};
    font-size: 1rem;
    font-weight: 400;
    line-height: 2;
    font-style: normal;  
    -webkit-overflow-scrolling: touch;
    overflow:scroll;
  }

  tspan {
    font-family: ${theme.bannerFont};
  }


  body.menu-fixed,
  body.drawer-fixed {
    overflow: hidden;
    width:100%;
    position: fixed;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-family: ${theme.headingPrimaryFont};
    color: ${theme.headingColor};
    &.uppercase {
      text-transform: uppercase;
    }
    &.centered {
      text-align: center;
    }
  }

  h1 {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.47;
    font-style: normal;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      margin-bottom: 0;
      font-size: 1.777rem;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.47;
    font-style: normal;
    text-align: center;
  }

  h3 {
    font-size: 1.333rem;
    font-weight: 400;
    line-height: 1.67;
    font-style: normal;
    text-align: center;
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      font-size: 1.25rem;
    }
  }

  h4 {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.67;
    font-style: normal;
  }

  h5 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2;
    font-style: normal;
  }

  h6 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    font-style: normal;
  }

  p {
    color: ${theme.bodyColor};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.9;
    font-style: normal;
    margin-top: 2vh;
    @media (max-width: ${theme.breakMedium}) {
      margin-top: 1.5rem;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    max-width: 100%;
    height:auto;
  }

  table {
    border: 1px solid ${theme.colors.painswick};
    border-collapse: collapse;
  }

  table td {
    border: 1px solid ${theme.colors.painswick};
    padding: 20px 30px;
  }

  table tr:nth-child(odd) {
    background: ${theme.colors.cloud};
  }

  .ReactModal__Overlay {
		opacity: 0;
		transition: opacity 400ms ease-in-out;
    z-index: 1;
	}

	.ReactModal__Overlay--after-open {
		opacity: 1;
	}

	.ReactModal__Overlay--before-close {
		opacity: 0;
	}

  .ReactModal__Content{
    width: 600px;
    @media (max-width: ${theme.breakSmall}) {
      width: 350px;
    }
  }
  
  button, a.button-class{
    display: block;
    -webkit-appearance: none;
    line-height: 1.2rem;
    border: 1px solid ${theme.colors.red};
    background-color: white;
    border-radius: 5px;
    outline: none;
    color: ${theme.colors.red};
    text-align: center;
    padding: 14px 0;
    width: 310px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover, &.button-active {
      background-color: ${theme.colors.red};
      color: white;
      transition: all 0.2s ease-in-out;
    }
    @media (max-width: ${theme.breakMedium}) {
      width: 100%;
    }
  }  

  text.v,
  text.v tspan,
  text.d,
  text.d tspan,
  text.aa,
  text.aa tspan {
    font-family: ${theme.bannerFont};
  }
  text.o,
  text.o tspan,
  text.p,
  text.p tspan {
    font-family: ${theme.timeBannerFont};
  }
`

const ThemeConfig = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet key="createGlobalStyle" />
      <GlobalStyle />
      <>{children}</>
    </ThemeProvider>
  )
}

export default ThemeConfig
